<template>
<div>
    <v-dialog v-model="dialogRIS" max-width="1200px" persistent scrollable>
        <v-card>
          <v-card-title class="headline pt-5">
            Requisition and Issuance Slip
            <v-spacer></v-spacer>
            <v-btn icon @click="closeDialog()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            </v-card-title>
          <v-card-text style="max-height: 900px">
          <v-form @submit.prevent ref="RISFormRef">
          <v-container>
            <v-row wrap align="center">
              <v-col cols="12" v-show="showForm">
                <v-row no-gutters>
                  <v-col cols="12" md="12">
                  <v-textarea
                    dense
                    outlined
                    color="header"
                    v-model="formData.RISPurpose"
                    :rules="[formRules.required]"
                    label="RIS Purpose"
                  ></v-textarea>
                  </v-col>
                  <v-col>
                    <v-autocomplete
                      v-model="formData.UserID"
                      label="Person Accountable"
                      :rules="[formRules.required]"
                      dense
                      outlined
                      hide-details
                      color="header"
                      :items="users"
                      item-text="name"
                      item-value="UserID"
                      type="text"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-data-table
                    v-model="selectedItems"
                    :headers="headers"
                    :items="itemList"
                    item-key="IARItemID_temp"
                    class="elevation-1 mt-4"
                    :loading="loading"
                    :show-select="showForm"
                >
                <template v-slot:top>
                  <v-toolbar flat>
                    <strong>List of Items</strong>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
                  <v-simple-checkbox
                    :value="isSelected"
                    :ripple="false"
                    :disabled="!item.isSelectable"
                    @input="select($event)"
                  ></v-simple-checkbox>
                </template>
                <template v-slot:[`item.StockPropertyNo`]="{ item }">
                  <v-edit-dialog
                    :return-value.sync="item.StockPropertyNo"
                    large
                  >
                  <span :style="item.ClassificationID != null && item.StockPropertyNo ? 'color:green' : item.ClassificationID == null && item.StockPropertyNo ? 'color: orange' : 'color:red'">
                    {{ item.StockPropertyNo ? item.StockPropertyNo : item.ClassificationID == null ? null : 'Please enter Stock/PropertyNo here...' }}
                  </span>
                    <template v-slot:input>
                      <v-textarea v-model="item.StockPropertyNo">
                      </v-textarea>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.ItemDescription`]="{ item }">
                  <v-edit-dialog
                    :return-value.sync="item.ItemDescription"
                    large
                  >
                    {{ item.ItemDescription }}
                    <template v-slot:input>
                      <v-textarea v-model="item.ItemDescription">
                      </v-textarea>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.UnitCost`]="{ item }">
                  <span class="text-no-wrap">₱ {{ formatPrice(item.UnitCost) || 0 }}</span>
                </template>
                <template v-slot:[`item.OverallQty`]="{ item }">
                  {{ formatQty(item.OverallQty) || 0 }}
                </template>
                <template v-slot:[`item.AllowableQty`]="{ item }">
                  {{ formatQty(item.AllowableQty) || 0 }}
                </template>
                <template v-slot:[`item.EditableQty`]="{ item }">
                    <v-edit-dialog
                        large
                        :return-value.sync="item.EditableQty"
                    >
                    <span
                        :style="parseFloat(item.AllowableQty) < parseFloat(item.EditableQty) || parseFloat(item.EditableQty) <= 0 ? 'color:red' : 'color:green'">
                    {{ formatQty(item.EditableQty) || 0 }}
                    </span>
                    <template v-slot:input>
                    <v-text-field
                        v-model="item.EditableQty"
                        :rules="[parseFloat(item.AllowableQty) >= parseFloat(item.EditableQty), parseFloat(item.EditableQty) > 0]"
                        type="number"
                    >
                    </v-text-field>
                    </template>
                    </v-edit-dialog>
                </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
          </v-form>
          </v-card-text>
          <v-card-actions>
            <span v-if="risData"><strong>IAR #:</strong> {{risData.IARCODE}}</span>
            <v-spacer></v-spacer>
            <v-btn color="grey darken-2" outlined @click="closeDialog()">Cancel</v-btn>
            <v-btn color="header" v-if="risData.edit" :loading="risLoading" class="white--text" :disabled="selectedItems.length == 0" @click="updateRIS()"><v-icon>mdi-pencil-box</v-icon> Update</v-btn>
            <v-btn color="header" v-show="showForm" v-else :loading="risLoading" class="white--text" :disabled="selectedItems.length == 0" @click="submitRIS()"><v-icon>mdi-check-circle</v-icon> Submit</v-btn>
          </v-card-actions>
        </v-card>
        <!-- <v-card>
          <v-card-title class="headline pt-5">Requisition and Issuance Slip</v-card-title>
          <v-card-text>
            <h2 align="center" class="ma-5">Coming Soon...</h2>
          </v-card-text>
        </v-card> -->
      </v-dialog>
      <v-dialog v-model="dialogConfirmSubmit" scrollable :max-width="showItems ? '1000px' : '500px'">
          <v-card>
            <v-card-title class="headline pt-5">Confirmation</v-card-title>
            <v-card-text style="max-height: 900px">
            <div>
              Are you sure you want to 
              <span v-if="risData.edit">update</span>
              <span v-else>submit</span>
               this Requisition and Issuance Slip?
            </div>
            <div v-if="showItems">
                <v-data-table
                    :headers="headers2"
                    :items="selectedItems"
                    item-key="IARItemID_temp"
                    class="elevation-1 mt-4"
                >
                <template v-slot:top>
                  <v-toolbar flat>
                    <strong>List of Items Selected</strong>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.UnitCost`]="{ item }">
                  <span class="text-no-wrap">₱ {{ formatPrice(item.UnitCost) || 0.00 }}</span>
                </template>
                <template v-slot:[`item.EditableQty`]="{ item }">
                  {{ formatQty(item.EditableQty) || 0 }}
                </template>
                <template v-slot:[`item.Amount`]="{ item }">
                  <span class="text-no-wrap">₱ {{ formatPrice(item.EditableQty * item.UnitCost) || 0.00 }}</span>
                </template>
                </v-data-table>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn color="grey darken-2" dark @click="showItems = !showItems">
                <span v-if="!showItems"><v-icon>mdi-eye</v-icon> Show Items</span>
                <span v-else><v-icon>mdi-eye-off</v-icon> Hide Items</span>
                </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="grey darken-2" outlined @click="dialogConfirmSubmit = false">Cancel</v-btn>
              <v-btn color="green darken-2 white--text" v-if="risData.edit" @click="confirmUpdateRIS()" :loading="risLoading"><v-icon>mdi-pencil-box</v-icon> Update</v-btn>
              <v-btn color="green darken-2 white--text" v-else @click="confirmSubmitRIS()" :loading="risLoading"><v-icon>mdi-check-circle</v-icon> Submit</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
</div>
</template>
<script>
export default {
  props: {
    risData: {},
  },
  data: () => ({
    dialogRIS: false,
    formData: {
      RISPurpose: null,
      UserID: null,
    },
    generatedRISID: [],
    risLoading: false,
    users: [],
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Saved!",
      message: "",
      top: 10,
    },
    headers: [
      {
        text: "Stock/Property No.",
        align: "start",
        sortable: false,
        value: "StockPropertyNo",
      },
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
      },
      { text: "Description", value: "ItemDescription", sortable: false },
      {
        text: "Unit Measure",
        value: "UnitMeasureDescription",
        sortable: false,
      },
      { text: "Unit Cost", value: "UnitCost", align: "center", sortable: false },
      { text: "Qty (Overall)", value: "OverallQty", align: "center", sortable: false },
      { text: "Qty (Allowable)", value: "AllowableQty", align: "center", sortable: false },
      { text: "Qty (Editable)", value: "EditableQty", align: "center", sortable: false },
    ],
    headers2: [
      {
        text: "Stock/Property No.",
        align: "start",
        sortable: false,
        value: "StockPropertyNo",
      },
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
      },
      { text: "Description", value: "ItemDescription", sortable: false },
      {
        text: "Unit Measure",
        value: "UnitMeasureDescription",
        sortable: false,
      },
      { text: "Unit Cost", value: "UnitCost", align: "center", sortable: false },
      { text: "Qty", value: "EditableQty", align: "center", sortable: false },
      { text: "Amount", value: "Amount", align: "center", sortable: false },
    ],
    itemList: [],
    selectedItems: [],
    loading: false,
    dialogConfirmSubmit: false,
    showForm: false,
    showItems: false,
  }),
  watch: {
    risData: {
      handler(data) {
        if (data.IARID) {
            this.formData.UserID = data.RecieverUserID
            if(data.edit){
                this.formData.UserID = data.UserID
                this.formData.RISPurpose = data.RISPurpose
            }
            this.dialogRIS = true;
            this.getItems();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getPersonAcountable();
  },
  methods: {
    closeDialog(){
      this.dialogRIS = false;
      this.dialogConfirmSubmit = false;
      this.formData.RISPurpose = null;
      this.formData.UserID = null;
      this.selectedItems = [];
      this.showForm = false;
      this.showItems = false;
      this.eventHub.$emit("closeCreateRIS", false);
    },
    submitRIS() {
      if (this.$refs.RISFormRef.validate() && this.selectedItems.length > 0) {
        let error = false;
        for (let list of this.selectedItems) {
          if ((parseFloat(list.EditableQty) > parseFloat(list.AllowableQty) || parseFloat(list.EditableQty) <= 0) || (list.ClassificationID != null && (list.StockPropertyNo == null || list.StockPropertyNo.trim() == ''))) {
            error = true;
          }
        }
        if (error) {
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "error";
          this.fadeAwayMessage.header = "System Message";
          this.fadeAwayMessage.message =
            "Failed to continue. Please check the details.";
        } else {
            this.dialogConfirmSubmit = true;
        }
      }
      else{
        this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "error";
          this.fadeAwayMessage.header = "System Message";
          this.fadeAwayMessage.message =
            "Failed to continue. Please check the details.";
      }
    },
    confirmSubmitRIS(){
      if(this.$refs.RISFormRef.validate() && this.selectedItems.length > 0){
      this.risLoading = true;
      let data = new FormData();
        data.append("IARID", this.risData.IARID);
        data.append("FundID", this.risData.FundID);
        data.append("SpecificFundSourceID", this.risData.SpecificFundSourceID);
        data.append("RISPurpose", this.formData.RISPurpose);
        data.append("UserID", this.formData.UserID);
        data.append("List", JSON.stringify(this.selectedItems));
        this.axiosCall("/ris/add", "POST", data).then((res) => {
        if(res.data.status){
          window.open(this.apiUrl + "/ris/report/" + res.data.data.RISID + "/1");
          this.closeDialog();
        }
        else{
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "success";
          this.fadeAwayMessage.message = "Something went wrong!";
          this.fadeAwayMessage.header = "System Message";
        }
        this.risLoading = false;
        });
      }
    },
    updateRIS() {
      if (this.$refs.RISFormRef.validate() && this.selectedItems.length > 0) {
        let error = false;
        for (let list of this.selectedItems) {
          if ((parseFloat(list.EditableQty) > parseFloat(list.AllowableQty)) || (list.ClassificationID != null && (list.StockPropertyNo == null || list.StockPropertyNo.trim() == ''))) {
            error = true;
          }
        }
        if (error) {
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "error";
          this.fadeAwayMessage.header = "System Message";
          this.fadeAwayMessage.message =
            "Failed to continue. Please check the details.";
        } else {
            this.dialogConfirmSubmit = true;
        }
      }
      else{
        this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "error";
          this.fadeAwayMessage.header = "System Message";
          this.fadeAwayMessage.message =
            "Failed to continue. Please check the details.";
      }
    },
    confirmUpdateRIS(){
      if(this.$refs.RISFormRef.validate()){
      this.risLoading = true;
      let data = new FormData();
        data.append("RISPurpose", this.formData.RISPurpose);
        data.append("UserID", this.formData.UserID);
        data.append("RISID", this.risData.RISID);
        data.append("List", JSON.stringify(this.selectedItems));
        this.axiosCall("/ris/edit", "POST", data).then((res) => {
        if(res.data.status){
        this.closeDialog();
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "success";
          this.fadeAwayMessage.message = "RIS was successfully updated";
          this.fadeAwayMessage.header = "System Message";
        }
        else{
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "error";
          this.fadeAwayMessage.message = "Something went wrong!";
          this.fadeAwayMessage.header = "System Message";
        }
        this.risLoading = false;
        });
      }
    },
    getPersonAcountable() {
      this.axiosCall("/getAllPersonsAccountable", "GET").then((res) => {
        this.users = res.data.data;
      });
    },
    getItems() {
      this.itemList = [];
      this.loading = true;
      let data = new FormData();
      data.append("IARID", this.risData.IARID);
      this.axiosCall("/iar/getItemsForRIS", "POST", data).then((res) => {
        this.itemList = res.data.data.result;
        if(this.risData.edit){
          console.log(this.risData)
        this.itemList.forEach(item1 => {
          this.risData.items.forEach(item2 => {
            if(item2.IARItemID == item1.IARItemID){
              item1.AllowableQty = parseFloat(item1.AllowableQty) + parseFloat(item2.Qty);
              item1.EditableQty = parseFloat(item2.Qty);
              item1.RISItemID = item2.RISItemID;
              item1.isSelectable = false;
              this.selectedItems.push(item1);
            }
          });
        });
        }
        this.itemList.forEach(item => {
          if(item.AllowableQty > 0){
            this.showForm = true;
          }
        });
        this.loading = false;
      });
    },
  },
};
</script>
        
    <style scoped>
.v-dialog--fullscreen,
.poModal {
  overflow-x: hidden !important;
}
</style>